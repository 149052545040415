// Navigation 

console.log('working js')



// Hamburger menu 
const menu = document.querySelector('.navigation__menu');
const menuButton = document.querySelector('.js-menu-button');
const menuButtonIcon = document.querySelector('.menu-icon');

menuButton.addEventListener('click', (e)=> {
    menu.classList.toggle('opened')
    menuButtonIcon.classList.toggle('opened')
})