import Swiper from 'swiper/bundle';

// Review 
const swiperReview = new Swiper('.js-swiper-review', {
    slidesPerView: 1,
    spaceBetween: 24,
    speed: 600,
    delay: 4000,
    touchRatio: 0.5,
    touchAngle: 30,
    pagination: {
      el: '.swiper-pagination',
      type: 'progressbar'
    },

    // Responsive breakpoints
    breakpoints: {
      768: {
        slidesPerView: 2,
        spaceBetween: 24,
      },

      1024: {
        slidesPerView: 3,
        spaceBetween: 24,
      }
    },
  });

  // Partner
  const swiperPartner = new Swiper('.js-swiper-partner', {
    slidesPerView: 1,
    spaceBetween: 24,
    speed: 600,
    delay: 4000,
    touchRatio: 0.5,
    touchAngle: 30,
    pagination: {
      el: '.swiper-pagination',
      type: 'progressbar'
    },

    // Responsive breakpoints
    breakpoints: {
      320: {
        slidesPerView: 2,
        spaceBetween: 16,
      },

      768: {
        slidesPerView: 3,
        spaceBetween: 24,
      },

      1024: {
        slidesPerView: 4,
        spaceBetween: 24,
      }
    },
  });
