// Input 

// Validate 

// Email vaild 
let emailValid = (email) => {
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (regexEmail.test(email)) {
        return true;
    } else {
        return false;
    }
};

// Text vaild 
let textValid = (text, minVal) => {
    console.log(text.length)

    if( text.length >= minVal) {
        return true
    } else {
        return false
    }
};

// Form Valid 

let formValid = () => {
    let event = new Event('input', {
        bubbles: true,
        cancelable: true,
    });

    let inputsValidate = document.querySelectorAll('.js-input-validate');

 

    Array.from(inputsValidate).forEach((input, index) => {
        input.dispatchEvent(event);
        console.log(input)
    });
}

let inputErrorMessage = (input, success) => {
    input.closest('.form__input').querySelector('.invalid').style.display = 'none'
    input.closest('.form__input').querySelector('.required').style.display = 'none'

    // Error message 
    if (input.value.length !== 0) {
        input.closest('.form__input').querySelector('.invalid').style.display = 'block'
    } else {
        input.closest('.form__input').querySelector('.required').style.display = 'block'
    }

    // Error class 
    if(success) {
        input.closest('.form__input').classList.remove('error')
        input.closest('.form__input').querySelector('.invalid').style.display = 'none'
        input.closest('.form__input').querySelector('.required').style.display = 'none'
    } else {
        input.closest('.form__input').classList.add('error')
    }
};


// Inputs 
let inputsValidate = document.querySelectorAll('.js-input-validate');

Array.from(inputsValidate).forEach((input, index) => {

    if (input.dataset.validate === 'email') {
        input.addEventListener('input', (e) => {
            inputErrorMessage(e.target, emailValid(e.target.value))
        })
    } else {
        input.addEventListener('input', (e) => {
            inputErrorMessage(e.target, textValid(e.target.value, 3))
        })
    }
})

let submitButton = document.querySelector('.js-form-submit');

submitButton.addEventListener('click', (e) => {
    formValid()
})